<template>
  <table :class="`ui-base-table ${className} ${isLoading && 'empty-table'}`">
    <BaseLoader v-if="isLoading" />
    <tr class="table-header" v-if="rows.length">
      <th
        v-for="(item, index) in columns"
        :key="index+item.title"
        :colspan="item.colspan"
        :class="`header-cell-${index+1}`"
      >
        <slot
          :name="`header${index}`"
          :data="item"
          :index="index"
        ><span>{{ item.title }}</span></slot>
      </th>
    </tr>
    <template
      v-for="(item, index) in rows"
      :key="`${index}-${item.cells[0]?.modelUuid || item.cells[0]?.id || item.cells.id}`"
    >
<!--      <div>{{ item.cells.id }}</div>-->
      <slot name="row" :data="item" :index="index">
        <tr>
          <td
            v-for="(itemChild, index) in item.cells"
            :key="index"
          >
            <slot
              name="cell"
              :data="itemChild"
              :index="index"
            ></slot>
          </td>
        </tr>
      </slot>
      <tr class="additional-info-line">
        <td v-show="isVisibleBlock[index]" :colspan="item.cells.length">
          <slot
            name="additional-info"
            :data="item"
            :index="index"
          ></slot>
        </td>
        <td v-show="isVisibleSecondBlock[index]" :colspan="item.cells.length">
          <slot
            name="additional-info-second"
            :data="item"
            :index="index"
          ></slot>
        </td>
      </tr>
    </template>
  </table>
</template>

<script src="./BaseTable.ts" lang="ts"/>
<style src="./BaseTable.scss" lang="scss"/>
