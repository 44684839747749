<template>
  <Paginator
    :rows="rowsPicker"
    :totalRecords="totalRecords"
    :rowsPerPageOptions="rowsPerPageOptions"
    v-model:first="firstItem"
    @page="onChange($event)"
    class="ui-paginator"
    template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
    ref="pagRef"
    :alwaysShow="false"
  >
    <template v-slot:right>
      <div class="ui-selector">
        <span>Показывать на странице</span>
        <Dropdown
          appendTo="self"
          :modelValue="rowsPicker"
          @change="changeRowsPerPage($event)"
          :options="rowsPerPageOptions"
        />
      </div>
      <div class="ui-to-page-input-wrapper">
        <span class="to-page-string">Перейти на страницу</span>
        <BaseInputNumber
          @input="changePage($event)"
          name="page"
          :value="page"
          :min="1"
          :step="1"
          :max="totalPages"
        />
      </div>
    </template>
  </Paginator>
</template>

<script src="./BasePaginator.ts" lang="ts"/>
<style src="./BasePaginator.scss" lang="scss" />
