import { defineComponent, ref, watch, onMounted } from 'vue';
import Paginator from 'primevue/paginator';
import Dropdown from 'primevue/dropdown';
import type { TSelectEvent } from '@/types/eventTypes';
import BaseInputNumber from '@/components/BaseComponents/BaseInputNumber/BaseInputNumber.vue';
import type { TPaginatorEvent } from '@/types/paginatorTypes';
import { isEqual } from 'lodash';

export default defineComponent({
  name: 'BasePaginator',
  components: {
    Paginator,
    Dropdown,
    BaseInputNumber
  },

  props: {
    totalRecords: {
      type: Number,
      default: 0
    },
    totalPages: {
      type: Number,
      default: null
    },
    sizeRows: {
      type: Number,
      default: 20
    },
    firstPageProp: {
      type: Number,
      default: 0
    },
    rowsPerPageOptions: {
      type: Array
    }
  },

  setup(props, { emit }) {
    const rowsPicker = ref(props.sizeRows);
    const firstItem = ref(0);
    const page = ref(1);
    const pagRef = ref();

    watch(() => props.firstPageProp, () => {
      firstItem.value = props.firstPageProp * props.sizeRows;
      page.value = props.firstPageProp + 1;
    });

    watch(() => props.sizeRows, () => {
      rowsPicker.value = props.sizeRows;
    });

    onMounted(() => {
      rowsPicker.value = props.sizeRows;
      page.value = props.firstPageProp + 1;
    });

    const prevEvt = ref();
    const onChange = (event: TPaginatorEvent) => {
      if (isEqual(event, prevEvt.value)) return;
      prevEvt.value = event;
      page.value = event.page + 1;
      emit('changed', event);
    };

    const changeRowsPerPage = (event: TSelectEvent) => {
      rowsPicker.value = event?.value;
      firstItem.value = 0;
      page.value = 1;
      emit('changed', {
        page: 0,
        first: 0,
        rows: event?.value
      });
    };

    const changePage = (e: number) => {
      if (e > props.totalPages) {
        pagRef.value.changePage(4);
      } else if (e < 1) {
        pagRef.value.changePage(1);
      } else {
        pagRef.value.changePage(e - 1);
      }
    };

    return {
      pagRef,
      page,
      changePage,
      onChange,
      rowsPicker,
      firstItem,
      changeRowsPerPage
    };
  }
});
