import { watch, defineComponent, PropType, ref } from 'vue';
import BaseLoader from '@/components/BaseComponents/BaseLoader/BaseLoader.vue';

type TTableColumn = {
  colspan: number;
  title: string;
  data?: any;
}

type TTableRow = {
  cells: any[];
}

export default defineComponent({
  name: 'BaseTable',

  components: {
    BaseLoader
  },

  props: {
    rows: {
      type: Array as PropType<TTableRow[]>,
      default: []
    },
    columns: {
      type: Array as PropType<TTableColumn[]>,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    className: {
      type: String
    }
  },

  setup(props) {
    const isVisibleBlock = ref(new Array(props.rows.length).fill(false));
    const isVisibleSecondBlock = ref(new Array(props.rows.length).fill(false));

    const toggleVisible = (index: number) => {
      isVisibleBlock.value[index] = !isVisibleBlock.value[index];
    };

    const toggleVisibleSecondBlock = (index: number) => {
      isVisibleSecondBlock.value[index] = !isVisibleSecondBlock.value[index];
    };

    watch(() => props.rows, () => {
      isVisibleBlock.value = new Array(props.rows.length).fill(false);
      isVisibleSecondBlock.value = new Array(props.rows.length).fill(false);
    });

    return {
      isVisibleBlock,
      isVisibleSecondBlock,
      toggleVisible,
      toggleVisibleSecondBlock
    };
  }

});
